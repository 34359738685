import { Switch } from '@mui/material'
import { Controller } from 'react-hook-form'

const ControlledSwitch = ({ name, label, control, disabled = false, rules = {}, ...props }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field, fieldState }) => {
      return (
        <Switch
          {...field}
          {...props}
          checked={field.value}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          fullWidth
          disabled={disabled}
        />
      )
    }}
  />
)

export default ControlledSwitch
