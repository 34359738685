import { useMemo } from 'react'
import { Box } from '@mui/material'
import CustomCard from '../../components/base/card'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import useAuthStore from "../../store/auth"
import { usePost } from '../../utils/hooks'
import AppointmentCalendar from '../../components/appointmentCalendar'
import { ICON_PROPS } from '../../utils/consts'
import { formatTimeToStr } from '../../utils/helpers'
import { format, setHours } from 'date-fns'
import EventAvailableIcon from '@mui/icons-material/EventAvailable'

const Dashboard = () => {
  const user = useAuthStore(state => state.user)
  const { data, error, isLoading } = usePost('appointment/getByDate', {
    data: {
      discordUser: user.discordUser,
      date: setHours(new Date(), 3).toUTCString()
    }
  })

  const { data: dataHoliday, error: errorHoliday, isLoading: loadingHoliday } = usePost('holiday/ListByMonth', {
    data: {
      discordUser: user.discordUser,
      date: setHours(new Date(), 3).toUTCString()
    }
  })

  const nextHoliday = useMemo(() => {
    if(loadingHoliday || errorHoliday) return
    let nextHoliday = ''
    const today = new Date().getTime()
    if(!dataHoliday.holidays) return '-'
    for(let h of dataHoliday.holidays) {
      let d = new Date(h.date).getTime()
      if(h.ExchangedBy?.length > 0) {
        d = new Date(h.ExchangedBy[0].date).getTime()
      }
      if(d > today) {
        if(!nextHoliday) {
          nextHoliday = d
        } else if (nextHoliday > d) {
          nextHoliday = d
        }
      }
    }
    return nextHoliday ? format(nextHoliday, 'dd/MM/yyyy') : '-'
  }, [dataHoliday, errorHoliday, loadingHoliday])

  const dailyWorkingTime = useMemo(() => {
    if (isLoading || error) return
    return formatTimeToStr(data.dailyWorkingTime)
  }, [data, isLoading, error])

  const annualLeave = useMemo(() => {
    return formatTimeToStr(user.annualLeave)
  }, [user])

  const onDateChangeHandler = () => {

  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between', gap: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', gap: '16px' }}>
        <CustomCard
          label="Banco de horas"
          value={annualLeave}
          icon={<AccessTimeIcon {...ICON_PROPS} />}
          iconBackground="primary.main"
          loading={isLoading}
        />
        <CustomCard
          label="Horas do dia"
          value={dailyWorkingTime}
          icon={<AccessTimeIcon {...ICON_PROPS} />}
          iconBackground="success.main"
          loading={isLoading}
        />
        <CustomCard
          label="Próximo Feriado"
          value={nextHoliday}
          icon={<EventAvailableIcon {...ICON_PROPS} />}
          iconBackground="purple"
          loading={loadingHoliday}
        />
      </Box >
      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', gap: '16px' }}>
        <AppointmentCalendar onDateChangeHandler={onDateChangeHandler} />
      </Box>
    </Box>
  )
}

export default Dashboard