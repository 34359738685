import { useEffect, useMemo } from 'react'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box from "@mui/material/Box"
import Stack from '@mui/material/Stack'
import { useForm } from "react-hook-form"
import ControlledDatePicker from '../forms/controlledDatePicker'
import ControlledSelect from '../forms/controlledSelect'
import Button from '@mui/material/Button'
import { useLazyPost } from '../../utils/hooks'
import MenuItem from '@mui/material/MenuItem'
import { format } from 'date-fns'
import useAuthStore from "../../store/auth"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

const DEFAULT_VALUES = {
  date: new Date(),
}

const NewHolidayExchangeModal = (props) => {
  const user = useAuthStore(state => state.user)
  const [listAvailable, { error, data, isLoading }] = useLazyPost('holiday/listAvailable')
  const { open, handleClose, saveHandler, values = {} } = props
  const { control, handleSubmit, reset } = useForm({ defaultValues: DEFAULT_VALUES })

  useEffect(() => {
    if (!open) return
    reset(DEFAULT_VALUES)
  }, [open, reset, values])

  useEffect(() => {
    listAvailable({ discordUser: user.discordUser })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const options = useMemo(() => {
    if(isLoading || error) return []
    if(!data.holidays) return []
    return data.holidays.map(h => {
      return <MenuItem value={h.id} key={h.id}>{format(h.date, 'dd/MM/yyyy')}</MenuItem>
    })
  }, [data, isLoading, error])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ paddingBottom: '32px' }}>
          Nova mudança de feriado
        </Typography>
        <form onSubmit={handleSubmit(saveHandler)}>
          <Stack spacing={2}>
            <ControlledSelect
              control={control}
              id="holidayId"
              name="holidayId"
              label="Feriado"
              variant="outlined"
              options={options}
            />
            <ControlledDatePicker
              control={control}
              id="date"
              name="date"
              label="Nova data"
              variant="outlined"
            />
            <Stack spacing={2} direction='row' sx={{ justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={handleClose}>Cancelar</Button>
              <Button variant="contained" type='submit' >Salvar</Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Modal>
  )
}

export default NewHolidayExchangeModal