import { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box from "@mui/material/Box"
import Stack from '@mui/material/Stack'
import { useForm } from "react-hook-form"
import ControlledTextField from '../forms/controlledTextField'
import ControlledSelect from '../forms/controlledSelect'
import ControlledSwitch from '../forms/controlledSwitch'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import useAuthStore from "../../store/auth"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
}

const DEFAULT_VALUES = {
  userType: false,
  userClass: 'worker',
  name: '',
  discordUser: '',
  email: '',
  password: '',
}

const NewUserModal = (props) => {
  const user = useAuthStore(state => state.user)
  const { open, handleClose, saveHandler, isEditMode = false, values = {} } = props
  const { control, handleSubmit, reset } = useForm({ defaultValues: isEditMode ? values : DEFAULT_VALUES })
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (!open) return
    reset(isEditMode ? values : DEFAULT_VALUES)
  }, [open, reset, isEditMode, values])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ paddingBottom: '32px' }}>
          {(isEditMode ? 'Editar ' : 'Novo ') + 'Usuário'}
        </Typography>
        <form onSubmit={handleSubmit(saveHandler)}>
          <Stack spacing={2}>
            <ControlledSelect
              control={control}
              id="userType"
              name="userType"
              label="Tipo"
              variant="outlined"
              disabled={isEditMode}
              options={[
                <MenuItem value={false} key={1}>Usuário</MenuItem>,
                <MenuItem value={true} key={2}>Admin</MenuItem>
              ]}
            />
            <ControlledSelect
              control={control}
              id="userClass"
              name="userClass"
              label="Cargo"
              variant="outlined"
              disabled={isEditMode}
              options={[
                <MenuItem value='worker' key={1}>Colaborador</MenuItem>,
                <MenuItem value='temp' key={2}>Estagiário</MenuItem>,
                <MenuItem value='hour' key={3}>Horista</MenuItem>,
              ]}
            />
            <ControlledTextField
              control={control}
              id="name"
              name="name"
              label="Nome"
              rules={{ required: { value: true, message: 'Campo é obrigatório' } }}
              variant="outlined"
            />
            <ControlledTextField
              control={control}
              id="discordUser"
              name="discordUser"
              label="Usuário Discord"
              rules={{ required: { value: true, message: 'Campo é obrigatório' } }}
              variant="outlined"
            />
            <ControlledTextField
              control={control}
              id="email"
              name="email"
              label="E-mail"
              rules={{ required: { value: true, message: 'Campo é obrigatório' } }}
              variant="outlined"
            />
            {!isEditMode &&
              <ControlledTextField
                control={control}
                id="password"
                name="password"
                label="Senha Temporária"
                type={showPassword ? 'text' : 'password'}
                rules={{ required: { value: true, message: 'Campo é obrigatório' } }}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(v => !v)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            }
            {isEditMode && user.id !== values.id &&
              <Stack direction='row' gap={1} sx={{alignItems: 'center'}} justifyContent='center'>
                <Typography>Inativo</Typography>
                <ControlledSwitch
                  control={control}
                  id="isActive"
                  name="isActive"
                  label="Status"
                  variant="outlined"
                />
                <Typography>Ativo</Typography>
              </Stack>
            }
            <Stack spacing={2} direction='row' sx={{ justifyContent: 'flex-end' }}>
              <Button variant="text" onClick={handleClose}>Cancelar</Button>
              <Button variant="contained" type='submit' >Salvar</Button>
            </Stack>
          </Stack>
        </form>
      </Box>
    </Modal>
  )
}

export default NewUserModal