import { useMemo, useState, useCallback } from 'react'
import CustomTable from '../../components/base/table'
import Box from "@mui/material/Box"
import { useGet, useLazyDelete, useLazyPost } from '../../utils/hooks'
import ConfirmationDialog from '../../components/confirmationDialog'
import { GridActionsCellItem } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { format } from "date-fns"
import NewHolidayModal from '../../components/newHolidayModal'


const COLUMNS = (deleteHandler) => [
  {
    field: 'date',
    headerName: 'Data',
    align: 'left',
    width: 180,
    sortable: true,
    valueGetter: val => format(val, 'dd/MM/yyyy')
  },
  {
    field: 'exchangeCount',
    headerName: 'Alterações',
    width: 320,
    align: 'left',
  },
  {
    field: 'exchangePending',
    headerName: 'Pendências',
    align: 'left'
  },
  {
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Deletar"
        onClick={() => deleteHandler(params.row)}
        showInMenu
      />,
    ],
  },
]


const HolidaysPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rowValue, setRowValue] = useState({})
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [deleteUser, { error: deleteError }] = useLazyDelete(`holiday/${rowValue.id}`)
  const [createHoliday, { error: createError }] = useLazyPost('holiday')
  const { data, error, isLoading, refetch } = useGet('holiday')

  const rows = useMemo(() => {
    if (error || isLoading) return []
    if (!data) return []
    return data.map(entry => {
      const exchangePending = entry.ExchangedBy.reduce((acc, cur) => cur.status === 0 ? acc + 1: acc, 0)
      return {
        id: entry.id,
        date: entry.date,
        exchangeCount: entry.ExchangedBy.length,
        exchangePending
      }
    })
  }, [error, isLoading, data])


  const closeModal = useCallback(() => {
    setRowValue({})
    setIsModalOpen(false)
  }, [])

  const saveHandler = async (formData) => {
    const data = {
      date: [formData.date]
    }
    await createHoliday(data)
    if(createError) {
      console.log(createError)
      return
    }

    refetch()
    setRowValue({})
    setIsModalOpen(false)
  }

  const deleteHandler = (data) => {
    setRowValue(data)
    setIsDeleteDialogOpen(true)
  }

  const deleteCancel = () => {
    setRowValue({})
    setIsDeleteDialogOpen(false)
  }

  const deleteOk = async () => {
    await deleteUser()
    if (deleteError) {
      console.log(deleteError)
    }
    setRowValue({})
    setIsDeleteDialogOpen(false)
    refetch()
  }

  const createHandler = () => {
    setIsModalOpen(true)
  }

  return (
    <Box sx={{ flex: 1 }}>
      <CustomTable
        rows={rows}
        loading={isLoading}
        columns={COLUMNS(deleteHandler)}
        label='Feriados'
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'asc' }],
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
      />
      <NewHolidayModal
        open={isModalOpen}
        handleClose={closeModal}
        saveHandler={saveHandler}
        values={rowValue}
      />
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title='Exclusão'
        message={`Deseja realmente excluir o feriado ${rowValue.date ? format(rowValue.date, 'dd/MM/yyyy'): ''}?`}
        handleClose={deleteCancel}
        handleOk={deleteOk}
      />
      <Box sx={{ position: 'fixed', right: 0, bottom: 0, margin: '32px 16px' }}>
        <Fab color="secondary" aria-label="fast check-in">
          <AddIcon onClick={createHandler} />
        </Fab>
      </Box>
    </Box>
  )
}

export default HolidaysPage