import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Controller } from 'react-hook-form'

const ControlledDatePicker = ({ name, label, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <DatePicker
        {...field}
        {...props}
        label={label}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    )}
  />
)

export default ControlledDatePicker