import { useEffect, useMemo } from 'react'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import Badge from '@mui/material/Badge'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { Paper, Typography } from "@mui/material"
import { useLazyPost } from '../../utils/hooks'
import { USER_CLASS_WORK_TIME } from '../../utils/consts'
import { isBefore, isSameDay, isWeekend, subDays } from 'date-fns'
import useAuthStore from "../../store/auth"

const ServerDay = (props) => {
  const { highlightedDays = {}, day, outsideCurrentMonth, holidays = [], loading, ...other } = props

  const isHoliday = holidays.some(d => isSameDay(d, day))

  const isSelected =
    !outsideCurrentMonth && ((isBefore(day, subDays(new Date(), 1)) && !isWeekend(day)) || (isWeekend(day) && highlightedDays[day.getDate()]) || isHoliday)

  let color = 'error.main'
  if (highlightedDays[day.getDate()]) {
    color = isWeekend(day) ? 'success.main' : `${highlightedDays[day.getDate()]}.main`
  }

  if(isHoliday) {
    if(highlightedDays[day.getDate()]) {
      color = 'success.main'
    } else {
      color = 'violet'
    }
  }

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isSelected && !loading ? ' ' : 0}
      variant="dot"
      sx={{
        "& .MuiBadge-badge": {
          color: "lightgreen",
          backgroundColor: color
        }
      }}
    >
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  )
}

const AppointmentCalendar = (props) => {
  const { onDateChangeHandler } = props
  const user = useAuthStore(state => state.user)
  const [loadMonthWorkDays, { data, isLoading, error }] = useLazyPost('appointment/getMonthWorkDays')
  const [loadingHolidays, { data: dataHoliday, error: errorHoliday, isLoading: loadingHoliday }] = useLazyPost('holiday/listByMonth')

  useEffect(() => {
    const params = {
      discordUser: user.discordUser,
      date: new Date()
    }
    loadMonthWorkDays(params)
    loadingHolidays(params)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const holidays = useMemo(() => {
    if(loadingHoliday || errorHoliday) return []
    console.log(dataHoliday)
    if (!dataHoliday.holidays) return []
    return dataHoliday.holidays.map(h => {
      if(h.ExchangedBy?.length > 0) {
        return h.ExchangedBy[0].date
      }
      return h.date
    })
  }, [dataHoliday, errorHoliday, loadingHoliday])

  const highlightedDays = useMemo(() => {
    if (error || isLoading || !data) return {}
    const days = {}
    data.days.forEach(({ day, time }) => {
      let status = 0
      if (time >= USER_CLASS_WORK_TIME[user.class] + 15 * 60) {
        status = 'success'
      } else if (time >= USER_CLASS_WORK_TIME[user.class]) {
        status = 'info'
      } else {
        status = 'warning'
      }
      days[day] = status
    })
    return days
  }, [data, error, isLoading, user])

  const handleMonthChange = (date) => {
    const params = {
      discordUser: user.discordUser,
      date
    }
    loadMonthWorkDays(params)
    loadingHolidays(params)
  }

  return (
    <Paper sx={{flex: 1}} elevation={2}>
      <Typography variant='h5' sx={{padding: '16px'}}>
        Calendário
      </Typography>
      <DateCalendar
        loading={isLoading}
        onMonthChange={handleMonthChange}
        slots={{
          day: ServerDay,
        }}
        onChange={onDateChangeHandler}
        slotProps={{
          day: {
            highlightedDays,
            loading: isLoading,
            holidays,
          },
        }}
      />
    </Paper>
  )
}

export default AppointmentCalendar