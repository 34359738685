import { useMemo, useState, useCallback, useEffect } from 'react'
import CustomTable from '../../components/base/table'
import useAuthStore from "../../store/auth"
import Box from "@mui/material/Box"
import { useLazyDelete, useLazyPost } from '../../utils/hooks'
import ConfirmationDialog from '../../components/confirmationDialog'
import { GridActionsCellItem } from '@mui/x-data-grid'
import DeleteIcon from '@mui/icons-material/Delete'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { format } from "date-fns"
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import NewHolidayExchangeModal from '../../components/newHolidayExchangeModal'

const STATUS_MAP = {
  0: 'Em avaliação',
  1: 'Aprovado',
  2: 'Recusado'
}

const COLUMNS = (deleteHandler, updateHandler, user) => [
  {
    field: 'date',
    headerName: 'Data original',
    align: 'left',
    width: 180,
    sortable: true,
    valueGetter: val => format(val, 'dd/MM/yyyy')
  },
  {
    field: 'newDate',
    headerName: 'Data atualizada',
    align: 'left',
    width: 180,
    sortable: true,
    valueGetter: val => format(val, 'dd/MM/yyyy')
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
    align: 'left',
    valueGetter: val => STATUS_MAP[val] || 'Desconhecido'
  },
  {
    field: 'user',
    headerName: 'Usuário',
    width: 120,
    align: 'left',
    valueGetter: u => u.name || 'Desconhecido'
  },
  {
    field: 'actions',
    type: 'actions',
    width: 80,
    getActions: (params) => {
      const actions = []
      if (user.isAdmin && params.row.status === 0) {
        const adminActions = [
          <GridActionsCellItem
            icon={<ThumbUpIcon />}
            label="Aprovar"
            onClick={() => updateHandler(params.row, true)}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<ThumbDownIcon />}
            label="Rejeitar"
            onClick={() => updateHandler(params.row, false)}
            showInMenu
          />
        ]
        actions.push(...adminActions)
      }

      if (params.row.user.id === user.id) {
        actions.push(
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Deletar"
            onClick={() => deleteHandler(params.row)}
            showInMenu
          />
        )
      }

      return actions
    }
  },
]


const HolidayExchangePage = () => {
  const user = useAuthStore(state => state.user)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [rowValue, setRowValue] = useState({})
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [deleteUser, { error: deleteError }] = useLazyDelete(`holiday/${rowValue.id}`)
  const [createHolidayExchange, { error: createError }] = useLazyPost('holidayExchange')
  const [loadHolidayExchange, { data, error, isLoading }] = useLazyPost('holidayExchange/list')
  const [updateExchangeStatus, {error: updateError}] = useLazyPost('holidayExchange/updateExchangeStatus')

  const loadData = useCallback(async (date = new Date()) => {
    const params = {
      discordUser: user.discordUser
    }
    loadHolidayExchange(params)
  }, [loadHolidayExchange, user])

  const rows = useMemo(() => {
    if (error || isLoading) return []
    if (!data) return []
    return data.map(entry => {
      return {
        id: entry.id,
        date: entry.Holiday.date,
        newDate: entry.date,
        status: entry.status,
        user: entry.User,
      }
    })
  }, [error, isLoading, data])

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = useCallback(() => {
    setRowValue({})
    setIsModalOpen(false)
  }, [])

  const saveHandler = async (formData) => {
    const data = {
      date: new Date(formData.date),
      holidayId: formData.holidayId,
      discordUser: user.discordUser
    }
    await createHolidayExchange(data)
    if(createError) {
      console.log(createError)
      return
    }

    loadData()
    setRowValue({})
    setIsModalOpen(false)
  }

  const deleteHandler = (data) => {
    setRowValue(data)
    setIsDeleteDialogOpen(true)
  }

  const updateHandler = async (row, approve) => {
    const data = {
      id: row.id,
      discordUser: user.discordUser,
      status: approve ? 1 : 2
    }
    await updateExchangeStatus(data)
    if (updateError) {
      console.log(updateError)
      return
    }
    loadData()
  }

  const deleteCancel = () => {
    setRowValue({})
    setIsDeleteDialogOpen(false)
  }

  const deleteOk = async () => {
    await deleteUser()
    if (deleteError) {
      console.log(deleteError)
    }
    setRowValue({})
    setIsDeleteDialogOpen(false)
    loadData()
  }

  const createHandler = () => {
    setIsModalOpen(true)
  }

  return (
    <Box sx={{ flex: 1 }}>
      <CustomTable
        rows={rows}
        loading={isLoading}
        columns={COLUMNS(deleteHandler, updateHandler, user)}
        label='Mudança de feriado'
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'asc' }],
          },
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
          columns: {
            columnVisibilityModel: {
              user: user.isAdmin
            },
          },
        }}
      />
      <NewHolidayExchangeModal
        open={isModalOpen}
        handleClose={closeModal}
        saveHandler={saveHandler}
        values={rowValue}
      />
      <ConfirmationDialog
        open={isDeleteDialogOpen}
        title='Exclusão'
        message={`Deseja realmente excluir o feriado ${rowValue.date ? format(rowValue.date, 'dd/MM/yyyy'): ''}?`}
        handleClose={deleteCancel}
        handleOk={deleteOk}
      />
      <Box sx={{ position: 'fixed', right: 0, bottom: 0, margin: '32px 16px' }}>
        <Fab color="secondary" aria-label="fast check-in">
          <AddIcon onClick={createHandler} />
        </Fab>
      </Box>
    </Box>
  )
}

export default HolidayExchangePage